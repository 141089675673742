import { db } from "utils/firebase";
import { Timestamp, collection, where, query, doc, orderBy, getDocs, getDoc } from "firebase/firestore";

export const getSubscriptions = async (payload) => {
  try {
    let q = query(collection(db, "customers"));
    const querySnapshot = await getDocs(q);

    let subscriptions = [];

    // customers
    await Promise.all(querySnapshot.docs.map(async (docCus) => {
      let qSubscription = query(collection(db, "customers", docCus.id, "subscriptions"),
        orderBy("created", "desc")
      );

      if (payload.fromDate) {
        qSubscription = query(qSubscription, where("created", ">", Timestamp.fromDate(new Date(payload.fromDate))));
      }
      if (payload.toDate) {
        qSubscription = query(qSubscription, where("created", "<", Timestamp.fromDate(new Date(`${payload.toDate} 23:59:59`))));
      }

      const querySubscriptionsSnapshot = await getDocs(qSubscription);

      await Promise.all(querySubscriptionsSnapshot.docs.map(async (subscription) => {
        let prices = [];

        await Promise.all(subscription.data().prices.map(async (docPrice) => {
          let price;
          const docPriceSnap = await getDoc(docPrice);

          if (docPriceSnap.exists()) {
            price = docPriceSnap.data();
            let product;
            const docProductRef = doc(db, "products", price?.product);
            const docProductSnap = await getDoc(docProductRef);

            if (docProductSnap.exists()) {
              product = docProductSnap.data();
            }

            prices.push({
              ...price,
              product
            });
          }
        }));

        subscriptions.push({
          subscriptionId: subscription.id,
          ...subscription.data(),
          created: subscription.data()?.created?.toDate(),
          cancel_at: subscription.data()?.cancel_at?.toDate(),
          canceled_at: subscription.data()?.canceled_at?.toDate(),
          current_period_start: subscription.data()?.current_period_start?.toDate(),
          current_period_end: subscription.data()?.current_period_end?.toDate(),
          downgradedCreatedAt: subscription.data()?.downgradedCreatedAt?.toDate(),
          prices,
          customer: docCus.data(),
        });
      }));
    }));

    return subscriptions;
  } catch (error) {
    console.log(error);
    return [];
  }
};
