import { storage } from 'utils/firebase'
import { uploadBytes, ref, getDownloadURL } from "firebase/storage";

import moment from "moment";

export const uploadFile = async (file) => {
    const storageRef = ref(storage, `post/${new moment().format('YYYYMMDD')}_${file.name}`);
    const snapshot = await uploadBytes(storageRef, file);
    return await getDownloadURL(snapshot.ref)
};
