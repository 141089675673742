import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, DatePicker, Row, Col, Input, Card, Space, Tag, message, Radio, Checkbox, Tabs } from 'antd';
import { SearchOutlined, EyeFilled, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import useAuth from 'hooks/useAuth';
import { getBillings } from 'apis/billing';

import _ from 'lodash';
import moment from 'moment';

import { Line } from '@ant-design/charts';

import './styles.less';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';

const { RangePicker } = DatePicker;

const { TabPane } = Tabs;

const Billings = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);
  const [billings, setBillings] = useState([]);
  const [billingsFilter, setBillingsFilter] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [fromDate, setFromDate] = useState(moment().subtract(3, 'months'));
  const [toDate, setToDate] = useState(moment());

  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("Monthly");

  const [showChart, setShowChart] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        //setLoading(true)
        if (user) {
          //fetch data
          setLoading(true)
          let payload = { fromDate: fromDate.unix(), toDate: toDate.unix() };

          const [payments] = await Promise.all([
            getBillings(payload),
          ]);

          console.log('payments getBillings:', payments)

          setBillings(payments);
          setBillingsFilter(payments);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    })();
  }, [user]);

  useEffect(() => {
    if (billingsFilter) {
      let days = {};
      const type = chartType === "Monthly" ? "month" : "day";
      const dateFormat = type === "month" ? "YYYY-MM" : "YYYY-MM-DD";

      let date = moment(toDate);
      while (moment(fromDate).isBefore(moment(date), type)) {
        days[date.format(dateFormat)] = 0;
        date = date.subtract(1, type);
      }

      days[date.format(dateFormat)] = 0;

      billingsFilter.forEach(item => {
        if ((moment.unix(item?.created).format(dateFormat) in days) && item.status === 'succeeded') {
          let price = item?.amount / 100;
          days[moment.unix(item?.created).format(dateFormat)] += price;
        }
      });

      setChartData(Object.keys(days).reverse().map(item => {
        return {
          date: item,
          amount: Math.round(days[item])
        }
      }));
    }
  }, [chartType, billingsFilter])

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(null);
      setToDate(null);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0]);
    }

    if (dates && dates[1]) {
      setToDate(dates[1]);
    }
  }

  console.log("billingsFilter:", billingsFilter);
  console.log("chartData:", chartData);

  const renderPlan = (prices) => {
    return prices.map((item, index) => {
      if (item?.tiers) {
        return (
          <div key={index}>
            {capitalizeFirstLetter(item?.product?.name)}
          </div>
        );
      } else {
        return (
          <div key={index}>
            {capitalizeFirstLetter(item?.product?.metadata?.package)} / {capitalizeFirstLetter(item?.product?.metadata?.cycle)}
          </div>
        );
      }
    })
  }

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  }

  const columns = [
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      width: '8%',
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => {
          return a.created - b.created
        }
      },
      render: (text, record, index) => {
        return (
          <div>
            <div>{new Date(record?.created * 1000).toUTCString()}</div>
          </div>
        );
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      render: (record) => {
        if (record === 'succeeded') {
          return <Tag color="blue">{record.toUpperCase()}</Tag>;
        } else {
          return <Tag color="default">{record.toUpperCase()}</Tag>;
        }

        // if (record === 'failed') {
        //   return <Tag color="red">{record.toUpperCase()}</Tag>;
        // } else if (record === 'canceled') {
        //   return <Tag color="green">{record.toUpperCase()}</Tag>;
        // } else if (record === 'pending') {
        //   return <Tag color="default">{record.toUpperCase()}</Tag>;
      },
      width: '5%',
      filterMultiple: true,
      filters: [
        {
          text: 'succeeded',
          value: 'succeeded',
        },
        // {
        //   text: 'failed',
        //   value: 'failed',
        // },
        // {
        //   text: 'canceled',
        //   value: 'canceled',
        // },
        // {
        //   text: 'pending',
        //   value: 'pending',
        // },
      ],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: 'Plan',
      dataIndex: 'subscription',
      width: '10%',
      render: (value, record) => {
        return (
          <div>{renderPlan(record?.prices)}</div>
        );
      },
    },
    {
      title: 'User',
      dataIndex: 'customer',
      width: '10%',
      render: (record) => {
        return (
          <div>
            <div>{record?.email}</div>
          </div>
        );
      }
    },
    {
      title: 'Price',
      dataIndex: 'amount',
      width: '5%',
      render: (value) => {
        return (
          <div>
            ${value / 100}
          </div>
        );
      }
    },
    {
      title: '',
      align: 'center',
      width: '8%',
      render: (record) =>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <Button
            target='_blank'
            href={record?.customer?.stripeLink}
            type='link'
            ghost
            icon={<EyeFilled />}
          >Detail</Button>
        </div>
    }
  ];

  const config = {
    loading: loading,
    data: chartData,
    xField: 'date',
    yField: 'amount',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
  };

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h2>Billings</h2>
                <Checkbox
                  style={{ marginLeft: 20, marginTop: 6 }}
                  defaultChecked={showChart}
                  onChange={(e) => {
                    setShowChart(e.target.checked);
                  }}
                >
                  Show chart
                </Checkbox>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  'Today': [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                }}
                onChange={onChange}
                defaultValue={[fromDate, toDate]}
              />
              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={() => {
                  setLoading(true)
                  let payload = {
                    fromDate: fromDate.unix(),
                    toDate: toDate.unix()
                  };

                  getBillings(payload)
                    .then(result => {
                      setBillings(result);
                      setBillingsFilter(result);
                      setLoading(false);
                    })
                    .catch(err => {
                      setLoading(false);
                    });
                }}
              >Search</Button>
              <Button
                style={{ float: 'right' }}
                type={'link'}
                icon={<DownloadOutlined />}
                onClick={() => {
                  const templateData = billingsFilter.map(item => {
                    return {
                      'Created At': moment.unix(item?.created).format('YYYY-MM-DD'),
                      'User': item?.customer?.email,
                      'Price': item?.amount / 100,
                      'Plan': renderPlan(item?.prices),
                      'Status': item?.status?.toUpperCase(),
                    };
                  });

                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `billings_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >Download</Button>
            </Col>
          </Row>
        </Card>
        {showChart &&
          <Card style={{ marginBottom: 18 }}>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
              <Col span={24}>
                <Card style={{ display: 'block' }}>
                  <div style={{ marginBottom: '20px' }}>
                    <Radio.Group value={chartType} onChange={(e) => setChartType(e.target.value)}>
                      <Radio.Button style={{ marginRight: '8px' }} value="Daily">Daily</Radio.Button>
                      <Radio.Button style={{ marginRight: '16px' }} value="Monthly">Monthly</Radio.Button>
                    </Radio.Group>
                  </div>
                  <Line {...config} />
                </Card>
              </Col>
            </Row>
          </Card>
        }

        <Tabs defaultActiveKey="1">
          <TabPane tab={`Success ${billingsFilter?.filter(item => item.status === "succeeded").length}`} key="1">
            <Table
              loading={loading}
              bordered
              columns={columns}
              dataSource={billingsFilter?.filter(item => item.status === "succeeded")}
              pagination={{
                defaultPageSize: 20
              }}
              summary={() => {
                if (!loading) {
                  const totalAmount = billingsFilter.filter(item => item.status === "succeeded").reduce((prev, item) => {
                    let price = 0;

                    if (item?.amount) {
                      price = parseFloat(item?.amount / 100);
                    }

                    return prev + price;
                  }, 0);

                  return (
                    <Table.Summary fixed>
                      <Table.Summary.Row>
                        <Table.Summary.Cell index={0} colSpan={7} className="summary-cell">Total Amount: {Math.round(totalAmount)} USD</Table.Summary.Cell>
                      </Table.Summary.Row>
                    </Table.Summary>
                  );
                }
              }}
            />
          </TabPane>
          <TabPane tab={`Canceled ${billingsFilter?.filter(item => item.status === "canceled").length}`} key="2">
            <Table
              loading={loading}
              bordered
              columns={columns}
              dataSource={billingsFilter?.filter(item => item.status === "canceled")}
              pagination={{
                defaultPageSize: 20
              }}
            />
          </TabPane>
          <TabPane tab={`Failed ${billingsFilter?.filter(item => item.status === "failed").length}`} key="3">
            <Table
              loading={loading}
              bordered
              columns={columns}
              dataSource={billingsFilter?.filter(item => item.status === "failed")}
              pagination={{
                defaultPageSize: 20
              }}
            />
          </TabPane>
          <TabPane tab={`Pending ${billingsFilter?.filter(item => item.status === "pending").length}`} key="4">
            <Table
              loading={loading}
              bordered
              columns={columns}
              dataSource={billingsFilter?.filter(item => item.status === "pending")}
              pagination={{
                defaultPageSize: 20
              }}
            />
          </TabPane>
        </Tabs>
      </Layout>
    </Layout>

  )
};

export default Billings;