/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { auth } from 'utils/firebase';
import { sendPasswordResetEmail } from "firebase/auth";
import './styles.less';

import { useNavigate } from 'react-router-dom';

import logo from 'assets/logo.png';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    sendPasswordResetEmail(auth, values.email)
      .then(() => {
        message.success("Reset password link has been sent to your email");
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;

        if (errorCode === "auth/user-not-found") {
          message.error("User not found");
        } else if (errorCode === "auth/invalid-email") {
          message.error("Email Wrong Format");
        }
        setLoading(false);
      });
  };

  return (
    <div className="login-page">
      <Row justify="space-between" style={{ height: window.innerHeight }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} style={{ alignSelf: 'center' }} className="paddingCol">
          <div className="login-logo">
            <img src={logo} alt="logo" style={{ height: 60, borderRadius: 10 }} />
            <h3 className="login-title" style={{ marginTop: 40, marginBottom: 40 }}>
              Forgot Password
            </h3>
          </div>
          <div>
            <Form
              name="loginForm"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label={'Email'}
                name="email"
                rules={[
                  { required: true, message: 'Email' },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={'Email'}
                />
              </Form.Item>
              <Form.Item>
                {loading === false ? (
                  <Button size="large" type="primary" htmlType="submit" block className="login-form-button">
                    Submit
                  </Button>
                ) : (
                  <Button size="large" type="primary" loading={loading} block className="login-form-button">
                    Loading
                  </Button>
                )}
              </Form.Item>
              <Form.Item>
                <p style={{ textAlign: "right" }}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/");
                    }}
                  >
                    Go back to Log In
                  </a>
                </p>
              </Form.Item>
            </Form>
          </div>
          <div style={{ paddingTop: '3vh', fontWeight: 'bold' }}>© 2023 NEEP. All rights reserved.</div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
