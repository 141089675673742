/* eslint-disable */
import request from "utils/request";
import MeiliSearch from "meilisearch";
import moment from "moment";
import _ from 'lodash';

const USAGE_NAME = "usages_neep";
const searchClient = new MeiliSearch({
  host: process.env.REACT_APP_MEILI_HOST || "https://ms-2d20a264a301-715.sgp.meilisearch.io",
  apiKey: process.env.REACT_APP_MEILI_API_KEY || "addf250309c7a8b56cbda9303970a2b252d2f4ca",
});

export const getUsages = async (payload) => {
  return request(`admin/usages`, payload, { method: "GET" })
};

export const getUsagesDashboard = async (payload) => {
  return request(`admin/usages-dashboard`, payload, { method: "GET" })
};

export const getAdminUsagesDashboard = async (payload) => {
  try {
    const { fromDate, toDate, email, appVersion, os } = payload;

    if (fromDate && toDate && (new Date(fromDate) > new Date(toDate))) {
      return {
        data: [],
        statusCode: 400,
        errorCode: "BAD_REQUEST",
        message: "start time must be smaller than end time"
      };
    }

    const usagesIndex = searchClient.index(USAGE_NAME);

    let filterArray = [];
    if (fromDate) filterArray.push(`createdAt_timestamp >= ${moment(fromDate).unix()}`);
    if (toDate) filterArray.push(`createdAt_timestamp <= ${moment(toDate).endOf("day").unix()}`);
    if (email) filterArray.push(`email = "${email}"`);
    if (appVersion && appVersion !== "all") filterArray.push(`appVersion = "${appVersion}"`);
    if (os && os !== "all") filterArray.push(`os = "${os}"`);

    const filterQuery = filterArray.join(" AND ");

    const HITS_PER_PAGE = 50000;

    const response = await usagesIndex.search("", {
      limit: 9999999,
      sort: ["createdAt_timestamp:desc"],
      filter: filterQuery,
      page: 1,
      hitsPerPage: HITS_PER_PAGE,
    });

    let data = [];

    if (response.totalPages > 1) {
      data.push(response);

      for await (const item of Array.from(Array(response.totalPages + 1).keys()).slice(2)) {
        const res = await usagesIndex.search("", {
          limit: 9999999,
          sort: ["createdAt_timestamp:desc"],
          filter: filterQuery,
          page: item,
          hitsPerPage: HITS_PER_PAGE,
        });
        data.push(res);
      }
    } else {
      data = [response];
    }

    return {
      message: "Get usages dashboard successfully!!!",
      statusCode: 200,
      data: _.flattenDeep(data.map(item => item?.hits), 1),
      // users: users,
    };
  } catch (error) {
    console.log("error:", error);
    return {
      data: [],
      statusCode: 500,
      errorCode: "INTERNAL_SERVER_ERROR",
      message: "something went wrong"
    };
  }
};

export const getAdminUsagesResellerDashboard = async (payload) => {
  return request(`admin/usages-reseller-dashboard`, payload, { method: "GET" })
};