import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Checkbox, message, Select } from "antd";
import { superUserUpdate } from "apis/superusers";

const SuperUserUpdateForm = ({ superUser = {}, onCancel, onCallback, token }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  return (
    <Form
      form={form}
      layout="vertical"
      name="superUserUpdateForm"
      onFinish={(values) => {
        setLoading(true);
        let item = {
          name: values?.name,
          role: values?.role,
          isActive: Boolean(values?.isActive),
          superUserId: superUser.superUserId
        };

        superUserUpdate(item, token)
          .then(() => {
            setLoading(false);
            message.success("Super user has been updated successfully");
            onCallback();
          })
          .catch((err) => {
            setLoading(false);
            message.error(err.message ?? "Error");
          });
      }}
      initialValues={superUser}
    >
      <div className="user-form">
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="email"
              label={"Email"}
              rules={[
                {
                  required: true,
                  message: "Email!",
                },
                {
                  type: "email",
                  message: "Invalid email!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="role"
              label="Role"
              rules={[
                {
                  required: true,
                  message: "Role!",
                },
              ]}
            >
              <Select
                optionFilterProp="children"
                style={{ width: "100%" }}
                placeholder="Role"
              >
                <Select.Option value="super">Super</Select.Option>
                <Select.Option value="sale">Sale</Select.Option>
                <Select.Option value="support">Support</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item
              name="isActive"
              label="Active"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
      </div>

      <div
        className="ant-modal-footer"
        style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}
      >
        <Row gutter={24} type="flex" style={{ textAlign: "right" }}>
          <Col className="gutter-row" span={24} style={{ textAlign: "right" }}>
            <Button type="clear" onClick={onCancel}>
              Cancel
            </Button>
            <Button loading={loading} type="primary" htmlType="submit">
              Save
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default SuperUserUpdateForm;
