/* eslint-disable */
import request from "utils/request";
import { db } from 'utils/firebase';
import { collection, query, doc, orderBy, getDocs, getDoc, addDoc, updateDoc, deleteDoc, serverTimestamp  } from "firebase/firestore";

export const versionList = async () => {
  const q = query(collection(db, "versions"), orderBy('createdAt', 'desc'));
  const querySnapshot = await getDocs(q);

  let versions = [];
  querySnapshot.forEach((doc) => {
    versions.push({
      versionId: doc.id,
      ...doc.data()
    });
  });

  return versions;
};

export const versionDetail = async (payload) => {
  const docRef = doc(db, "versions", payload?.versionId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      ...docSnap.data(), 
      versionId: docSnap.id,
    }
  } else {
    return undefined
  }
};

export const versionCreate = async (payload) => {
  try {
    const docRef = await addDoc(collection(db, "versions"), {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: docRef.id
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const versionUpdate = async (payload) => {
  try {
    const versionId = payload?.versionId;
    delete payload.versionId;
    await updateDoc(doc(db, "versions", versionId), {
      ...payload,
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: versionId
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};
