/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, DatePicker, Row, Col, Input, Card, Space, Radio, message, Select } from 'antd';
import { SearchOutlined, DownloadOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import { getAdminUsagesDashboard } from 'apis/usage';

import _ from 'lodash';
import moment from 'moment';

import { Line } from '@ant-design/charts';

import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from "recharts";

import './styles.less';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import { versionList } from 'apis/version';
import { getAllUsers } from 'apis/user';

const { RangePicker } = DatePicker;

const COLORS = ["#0072f0", "#2687f2", "#4d9cf5", "#73b1f7", "#99c7f9"];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, type }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  const percentChart = (percent * 100).toFixed(0);

  return (
    <text x={x} y={y} fill="#000" textAnchor="middle" dominantBaseline="central">
      {percentChart >= 5 && type !== "Others" ? `${percentChart}%` : ""}
    </text>
  );
};

const capitalizeFirstLetter = (string) => {
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

const Usage = () => {
  const [loading, setLoading] = useState(false);

  const searchInputEl = useRef(null);
  const [usagesFilter, setUsagesFilter] = useState([]);

  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [, setSearchedColumn] = useState('');

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days'));
  const [toDate, setToDate] = useState(moment());

  const [chartData, setChartData] = useState([]);
  const [chartType, setChartType] = useState("Daily");
  const [appUsingsData, setAppUsingsData] = useState([]);
  const [appOsData, setAppOsData] = useState([]);
  const [appVersionData, setAppVersionData] = useState([]);

  const [appVersion, setAppVersion] = useState();
  const [os, setOs] = useState();

  const [versions, setVersions] = useState([]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(null);
      setToDate(null);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    } else {
      setFromDate()
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    } else {
      setToDate()
    }
  }

  const convertToDuration = (value) => {
    let diffInMilliSeconds = Math.round(value / 1000);

    // calculate days
    const days = Math.floor(diffInMilliSeconds / (3600 * 24));
    diffInMilliSeconds -= days * 3600 * 24;

    // calculate hours
    const hours = Math.floor(diffInMilliSeconds / 3600);
    diffInMilliSeconds -= hours * 3600;

    // calculate minutes
    const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    // calculate minutes
    const seconds = diffInMilliSeconds;

    if (days > 0) {
      return `${days < 10 ? "0" : ""}${days}:${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
        }${seconds}`;
    }
    return `${hours < 10 ? "0" : ""}${hours}:${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""
      }${seconds}`;
  };

  const columns = [
    {
      title: "Last usage",
      dataIndex: "lastUsage",
      width: "20%",
      sorter: {
        compare: (a, b) => {
          return moment(a.lastUsage) - moment(b.lastUsage);
        },
      },
      defaultSortOrder: "descend",
      render: (record) => {
        return (
          <div>
            <div>{moment(record).format("YYYY-MM-DD HH:mm")}</div>
          </div>
        );
      }
    },
    {
      title: "Email",
      width: "30%",
      ...getColumnSearchProps("email")
    },
    {
      title: "Total Duration",
      dataIndex: "totalDuration",
      width: "25%",
      sorter: {
        compare: (a, b) => a.duration.localeCompare(b.duration),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      width: "20%",
      key: "action",
      render: (record) => {
        return (
          <div style={{ textAlign: "center" }}>
            {record?.details.length > 0 &&
              <Button
                type="link"
                icon={<DownloadOutlined />}
                onClick={() => {
                  const templateData = record?.details?.map(item => {
                    return {
                      "Created At": item?.createdAt,
                      "Duration": item?.duration,
                      "Start Time": item?.startTime,
                      "End Time": item?.endTime,
                      "OS": item?.os,
                      "App Version": item?.appVersion,
                      "Model Version": item?.modelVersion,
                      "Current Mic": item?.curMic,
                      "Current Speaker": item?.curSpeaker,
                      "App Using": item?.appUsings
                    };
                  });

                  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                  const fileExtension = '.xlsx';
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `usages_${record?.email}_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >
                Download
              </Button>
            }
          </div>
        );
      }
    },
  ];

  const columnsMember = [
    {
      title: "Duration",
      dataIndex: "duration",
      width: "5%",
      sorter: {
        compare: (a, b) => a.duration.localeCompare(b.duration),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      width: "5%",
      sorter: {
        compare: (a, b) => { return moment(a.startTime) - moment(b.startTime) },
      },
      render: (record) => {
        return (
          <div>
            <div>{moment(record).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        );
      }
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      width: "5%",
      sorter: {
        compare: (a, b) => { return moment(a.endTime) - moment(b.endTime) },
      },
      render: (record) => {
        return (
          <div>
            <div>{moment(record).format("YYYY-MM-DD HH:mm:ss")}</div>
          </div>
        );
      }
    },
    {
      title: "OS",
      dataIndex: "os",
      width: "5%",
      filterMultiple: true,
      filters: [
        {
          text: "Windows",
          value: "win"
        },
        {
          text: "Mac",
          value: "mac"
        },
        {
          text: "Linux",
          value: "linux"
        }
      ],
      onFilter: (value, record) => record.os === value,
      render: (record) => {
        return (
          <div>
            <div>{record === "win" ? "Windows" : (record === "mac" ? "Mac" : "Linux")}</div>
          </div>
        );
      }
    },
    {
      title: "App Version",
      dataIndex: "appVersion",
      width: "5%",
      sorter: {
        compare: (a, b) => a.appVersion.localeCompare(b.appVersion),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      title: "Model Version",
      dataIndex: "modelVersion",
      width: "5%",
      sorter: {
        compare: (a, b) => a.modelVersion.localeCompare(b.modelVersion),
      },
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
    {
      title: "App Using",
      dataIndex: "appUsings",
      width: "8%",
      render: (record) => {
        return (
          <div>
            <div>{record}</div>
          </div>
        );
      }
    },
  ];

  const config = {
    loading: loading,
    data: chartData,
    xField: 'date',
    yField: 'users',
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    tooltip: {
      showTitle: true,
      title: (value) => {
        if (chartType === "Weekly") {
          const date = value.split("W");
          if (date.length === 2) {
            const fromDate = moment().day("Monday").isoWeek(date[1]).format("YYYY-MM-DD");
            const toDate = moment().day("Sunday").isoWeek(date[1]).format("YYYY-MM-DD");

            return `${value} \n\n (${fromDate} -> ${toDate})`;
          } else {
            return value;
          }
        } else {
          return value;
        }
      },
    },
  };

  const expandedRowRender = (record) => {
    return (
      <Table
        loading={loading}
        bordered
        columns={columnsMember}
        dataSource={record.details}
        pagination={false}
      />
    )
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      let name = payload[0].name;
      if (name === "Others") {
        name = "Others";
      }

      return (
        <div className="bg-white border border-[#ddd] p-3 rounded-lg text-sm custom-tooltip">
          <p className="label">
            {`${name}: ${convertToDuration(payload[0].value)}`}
          </p>
        </div>
      );
    }

    return null;
  };

  const RenderPieChart = ({ pieData, loading }) => {
    return (
      <Col lg={{ span: 12 }} xs={{ span: 24 }}>
        <div>
          {loading ?
            <>
              <ResponsiveContainer className="!h-[400px] block pie-chart-large">
                <PieChart>
                  <Legend
                    align="center"
                    verticalAlign="bottom"
                  />
                  <Pie
                    data={[{
                      type: "",
                      duration: 1
                    }]}
                    fill="#eee"
                    dataKey="duration"
                    nameKey="type"
                    labelLine={false}
                    cx="50%"
                    cy="50%"
                    innerRadius={40}
                    legendType="none"
                    startAngle={90}
                    endAngle={-270}
                    isAnimationActive={false}
                  >
                    <Cell fill="#eee" className="animate-pulse" />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </>
            :
            <>
              {pieData.length > 0 ? (
                <>
                  <ResponsiveContainer className="!h-[400px] block pie-chart-large"                  >
                    <PieChart>
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        formatter={(value) => {
                          let valueRender = value;
                          if (valueRender === "Others") {
                            valueRender = "Others";
                          }

                          return <span style={{ color: "#000", fontSize: "14px" }}>{valueRender}</span>;
                        }}
                      />
                      <Pie
                        data={pieData}
                        fill="#8884d8"
                        dataKey="duration"
                        nameKey="type"
                        labelLine={false}
                        label={renderCustomizedLabel}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        legendType="circle"
                        startAngle={90}
                        endAngle={-270}
                      >
                        {pieData.map((entry, index) => {
                          return (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          );
                        })}
                      </Pie>
                      <Tooltip content={<CustomTooltip />} />
                    </PieChart>
                  </ResponsiveContainer>
                </>
              ) : (
                <>
                  <ResponsiveContainer className="!h-[400px] block pie-chart-large"                  >
                    <PieChart>
                      <Legend
                        align="center"
                        verticalAlign="bottom"
                        formatter={(value) => {
                          let valueRender = value;
                          if (valueRender === "Others") {
                            valueRender = "Others";
                          }

                          return <span style={{ color: "#000", fontSize: "14px" }}>{valueRender}</span>;
                        }}
                        style={{ display: "inline-flex", alignItems: "center" }}
                      />
                      <Pie
                        data={[{
                          type: "No data",
                          duration: 1
                        }]}
                        fill="#eee"
                        dataKey="duration"
                        nameKey="type"
                        labelLine={false}
                        cx="50%"
                        cy="50%"
                        innerRadius={40}
                        legendType="circle"
                        startAngle={90}
                        endAngle={-270}
                        isAnimationActive={false}
                      >
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </>
              )
              }
            </>
          }
        </div>
      </Col>
    );
  };

  // useEffect(() => {
  //   (async () => {
  //     try {
  //       if (user) {
  //         // fetch data
  //         setLoading(true)
  //         let payload = {
  //           fromDate: moment().clone().subtract(7, 'days').format("YYYY-MM-DD")
  //         };

  //         const [result, users] = await Promise.all([
  //           getUsagesDashboard(payload),
  //           userList({})
  //         ]);

  //         setUsers(users);
  //         setUsagesFilter(result.data);
  //         setLoading(false);
  //       }
  //     } catch (e) {
  //       setLoading(false);
  //     }
  //   })();
  // }, [user]);

  useEffect(() => {
    (async () => {
      try {
        const [result, result2] = await Promise.all([
          versionList(),
          getAllUsers(),
        ]);

        const newVersions = _.uniq(result.map(item => {
          return item.version;
        }));

        setVersions(newVersions);
        setUsers(result2.data);
      } catch (error) {
        console.log("error:", error);
      }
    })();
  }, []);

  const handleChartData = (data) => {
    let oldData = Object.keys(data).map(item => {
      return {
        type: item,
        hours: _.round(data[item] / 3600000, 1),
        duration: _.round(data[item], 1)
      };
    }).filter(pie => pie.duration > 0).sort((a, b) => b.duration - a.duration);

    let newData = [];
    const maxLength = 6;

    // Nếu tìm thấy dùng CrystalSound MVO thì đưa vào Others
    const crystalsoundIndex = oldData.findIndex(item => item.type === "CrystalSound MVO.exe");

    if (crystalsoundIndex !== -1) {
      const othersIndex = oldData.findIndex(item => item.type === "Others");
      if (othersIndex !== -1) {
        const newDuration = oldData[othersIndex].duration + oldData[crystalsoundIndex].duration;
        oldData[othersIndex] = {
          ...oldData[othersIndex],
          type: "Others",
          duration: _.round(newDuration, 1),
          hours: _.round(newDuration / 3600000, 1)
        };
        oldData = oldData.filter(item => item.type !== "CrystalSound MVO.exe");
      } else {
        oldData[crystalsoundIndex] = {
          ...oldData[crystalsoundIndex],
          type: "Others"
        };
      }
    }

    if (oldData.length > maxLength) {
      newData = oldData.slice(0, maxLength);

      const dataSlice = oldData.slice(maxLength);
      const othersIndex = oldData.findIndex(item => item.type === "Others");

      let totalDuration = 0;
      dataSlice.length > 0 && dataSlice.forEach(item => {
        totalDuration += item.duration;
      });

      // Khi data tồn tại Others
      if (othersIndex !== -1) {
        if (othersIndex >= maxLength) {
          newData[maxLength - 1] = {
            type: "Others",
            duration: _.round((newData[maxLength - 1].duration + totalDuration), 1),
            hours: _.round((newData[maxLength - 1].duration + totalDuration) / 3600000, 1)
          };
        } else {
          newData[othersIndex] = {
            ...newData[othersIndex],
            duration: _.round((newData[othersIndex].duration + totalDuration), 1),
            hours: _.round((newData[othersIndex].duration + totalDuration) / 3600000, 1)
          };
        }
      } else {
        newData[maxLength - 1] = {
          type: "Others",
          duration: _.round((newData[maxLength - 1].duration + totalDuration), 1),
          hours: _.round((newData[maxLength - 1].duration + totalDuration) / 3600000, 1)
        };
      }
    } else {
      newData = [...oldData];
    }

    newData = newData.sort((a, b) => b.duration - a.duration);
    const findIndex = newData.findIndex(item => item.type === "Others");

    if (findIndex !== -1) {
      const tempData2 = newData.filter(item => item.type !== "Others");
      newData = [...tempData2, newData[findIndex]];
    }

    let colorIndex = 0;
    newData = newData.map(item => {
      if (item.type === "Others") {
        return {
          ...item,
          color: "#f4f4f4"
        };
      } else {
        return {
          ...item,
          color: COLORS[colorIndex++]
        };
      }
    });

    return newData;
  };

  const handleChartOsData = (data) => {
    let oldData = Object.keys(data).map(item => {
      return {
        type: item,
        hours: _.round(data[item] / 3600000, 1),
        duration: _.round(data[item], 1)
      };
    }).filter(pie => pie.duration > 0).sort((a, b) => b.duration - a.duration);

    let newData = [];
    const maxLength = 6;

    if (oldData.length > maxLength) {
      newData = oldData.slice(0, maxLength);

      const dataSlice = oldData.slice(maxLength);
      const othersIndex = oldData.findIndex(item => item.type === "Others");

      let totalDuration = 0;
      dataSlice.length > 0 && dataSlice.forEach(item => {
        totalDuration += item.duration;
      });

      // Khi data tồn tại Others
      if (othersIndex !== -1) {
        if (othersIndex >= maxLength) {
          newData[maxLength - 1] = {
            type: "Others",
            duration: _.round((newData[maxLength - 1].duration + totalDuration), 1),
            hours: _.round((newData[maxLength - 1].duration + totalDuration) / 3600000, 1)
          };
        } else {
          newData[othersIndex] = {
            ...newData[othersIndex],
            duration: _.round((newData[othersIndex].duration + totalDuration), 1),
            hours: _.round((newData[othersIndex].duration + totalDuration) / 3600000, 1)
          };
        }
      } else {
        newData[maxLength - 1] = {
          type: "Others",
          duration: _.round((newData[maxLength - 1].duration + totalDuration), 1),
          hours: _.round((newData[maxLength - 1].duration + totalDuration) / 3600000, 1)
        };
      }
    } else {
      newData = [...oldData];
    }

    newData = newData.sort((a, b) => b.duration - a.duration);
    const findIndex = newData.findIndex(item => item.type === "Others");

    if (findIndex !== -1) {
      const tempData2 = newData.filter(item => item.type !== "Others");
      newData = [...tempData2, newData[findIndex]];
    }

    let colorIndex = 0;
    newData = newData.map(item => {
      if (item.type === "Others") {
        return {
          ...item,
          color: "#f4f4f4"
        };
      } else {
        return {
          ...item,
          color: COLORS[colorIndex++]
        };
      }
    });

    return newData;
  };

  useEffect(() => {
    if (usagesFilter) {
      let dates = {};

      let date = moment(toDate);
      while (moment(fromDate).isBefore(moment(date), "day")) {
        dates[date.format("YYYY-MM-DD")] = 0;
        date = date.subtract(1, "days");
      }

      dates[date.format("YYYY-MM-DD")] = 0;

      usagesFilter.forEach(usage => {
        const date = moment(usage.startTime).format("YYYY-MM-DD");
        if (date in dates) {
          dates[date] += (usage.durationValue / 3600000);
        }
      });

      const newUsagesFilter = usagesFilter.filter(usage => {
        const date = moment(usage.startTime).format("YYYY-MM-DD");
        if (date in dates) {
          return true;
        }
      });

      let pieAppsUsings = {};
      let pieAppOs = {};
      let pieAppVersion = {};

      newUsagesFilter.forEach(usage => {
        // Handle pieAppsUsings
        if (usage.type === "recording") {
          if (pieAppsUsings["Recording Magic"]) {
            pieAppsUsings["Recording Magic"] += usage.durationValue;
          } else {
            pieAppsUsings["Recording Magic"] = usage.durationValue;
          }
        } else {
          if (usage.appSettings) {
            const micAppsUsings = usage.appSettings?.micAppsUsing ? _.uniq(usage.appSettings?.micAppsUsing.split(",")) : [];
            const speakerAppsUsings = usage.appSettings?.speakerAppsUsing ? _.uniq(usage.appSettings?.speakerAppsUsing.split(",")) : [];
            const appUsings = _.union(micAppsUsings, speakerAppsUsings);

            // Thêm data vào appsUsings
            if (appUsings.length > 0) {
              appUsings.forEach(appUsing => {
                if (!appUsing) {
                  if (pieAppsUsings.Others) {
                    pieAppsUsings.Others += usage.durationValue;
                  } else {
                    pieAppsUsings.Others = usage.durationValue;
                  }
                } else {
                  if (pieAppsUsings[appUsing]) {
                    pieAppsUsings[appUsing] += usage.durationValue;
                  } else {
                    pieAppsUsings[appUsing] = usage.durationValue;
                  }
                }
              });
            } else {
              if (pieAppsUsings.Others) {
                pieAppsUsings.Others += usage.durationValue;
              } else {
                pieAppsUsings.Others = usage.durationValue;
              }
            }
          } else {
            // Data không có appSettings thì thêm vào
            if (pieAppsUsings.Others) {
              pieAppsUsings.Others += usage.durationValue;
            } else {
              pieAppsUsings.Others = usage.durationValue;
            }
          }
        }

        // Handle pieAppOs
        if (usage.os) {
          if (pieAppOs[usage.os]) {
            pieAppOs[usage.os] += usage.durationValue;
          } else {
            pieAppOs[usage.os] = usage.durationValue;
          }
        } else {
          if (pieAppOs.Others) {
            pieAppOs.Others += usage.durationValue;
          } else {
            pieAppOs.Others = usage.durationValue;
          }
        }

        // Handle pieAppVersion
        if (usage.appVersion) {
          if (pieAppVersion[usage.appVersion]) {
            pieAppVersion[usage.appVersion] += usage.durationValue;
          } else {
            pieAppVersion[usage.appVersion] = usage.durationValue;
          }
        } else {
          if (pieAppVersion.Others) {
            pieAppVersion.Others += usage.durationValue;
          } else {
            pieAppVersion.Others = usage.durationValue;
          }
        }
      });

      const newPieAppOs = handleChartOsData({ ...pieAppOs });
      setAppOsData(newPieAppOs);

      const newPieAppVersion = handleChartOsData({ ...pieAppVersion });
      setAppVersionData(newPieAppVersion);

      const newPieAppsUsings = handleChartData({ ...pieAppsUsings });
      setAppUsingsData(newPieAppsUsings);
    }
  }, [usagesFilter]);

  useEffect(() => {
    if (usagesFilter) {
      let days = {};
      const type = chartType === "Monthly" ? 'month' : chartType === "Weekly" ? 'week' : 'day';
      const dateFormat = type === 'month' ? "YYYY-MM" : type === 'day' ? "YYYY-MM-DD" : "YYYY-[W]WW";

      let date = moment(toDate);
      while (moment(fromDate).isBefore(moment(date), type)) {
        days[date.format(dateFormat)] = [];
        date = date.subtract(1, type);
      }

      days[date.format(dateFormat)] = [];

      usagesFilter.forEach(usage => {
        const date = moment(usage.startTime).format(dateFormat);
        let isInvalid = false;

        if (fromDate && toDate) {
          isInvalid = moment(usage.startTime).isBefore(fromDate.clone().startOf("day")) || moment(usage.startTime).isAfter(toDate.clone().endOf("day"));
        }

        if (!isInvalid) {
          if (date in days) {
            days[date].push(usage.email);
          }
        }
      });

      setChartData(Object.keys(days).reverse().map(item => {
        return {
          date: item,
          usersDetail: _.uniq(days[item]),
          users: _.uniq(days[item])?.length
        };
      }));
    }
  }, [usagesFilter, chartType]);

  useEffect(() => {
    const dataGroupBy = _.groupBy(usagesFilter, function (item) {
      return item?.email
    });

    const data = [];

    for (const [key, value] of Object.entries(dataGroupBy)) {
      let totalDurationValue = 0;
      let details = [];

      value.forEach(usage => {
        const { appSettings, ...rest } = usage;
        let appUsings = [];
        let curMic = "";
        let curSpeaker = "";

        if (rest.type === "recording") {
          appUsings = ["Recording Magic"];
        } else {
          if (appSettings) {
            const micAppsUsings = appSettings?.micAppsUsing ? _.uniq(appSettings?.micAppsUsing.split(",")) : [];
            const speakerAppsUsings = appSettings?.speakerAppsUsing ? _.uniq(appSettings?.speakerAppsUsing.split(",")) : [];
            appUsings = _.union(micAppsUsings, speakerAppsUsings);

            if (appUsings.length <= 0) {
              appUsings = ["Unknown"];
            }
          } else {
            appUsings = ["Unknown"];
          }
        }

        curMic = appSettings?.curMic;
        curSpeaker = appSettings?.curSpeaker;
        totalDurationValue += usage.durationValue;

        details.push({
          ...rest,
          appSettings,
          appUsings: appUsings.join(", "),
          curMic,
          curSpeaker
        });
      });

      const item = {
        email: key,
        totalDurationValue,
        totalDuration: convertToDuration(totalDurationValue),
        lastUsage: value[0].createdAt,
        details
      };

      data.push(item);
    }

    setData(data);
  }, [usagesFilter]);

  // console.log("usagesFilter:", usagesFilter);
  // console.log("USERS:", users);
  // console.log("data:", data);

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Usages</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  'Today': [moment(), moment()],
                  'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                  'This Week': [moment().startOf('week'), moment().endOf('week')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                  'This Quarter': [moment().startOf('quarter'), moment().endOf('quarter')],
                  'This Year': [moment().startOf('year'), moment().endOf('year')],
                }}
                onChange={onChange}
                defaultValue={[moment().clone().subtract(7, 'days'), moment()]}
                allowClear={false}
              />
              <Select
                placeholder="Version"
                style={{ width: 90, marginLeft: 16 }}
                value={appVersion}
                onChange={(value) => {
                  setAppVersion(value);
                }}
              >
                {versions.map((version) => {
                  return <Select.Option value={version} key={version}>{version}</Select.Option>;
                })}
                <Select.Option value="all">All</Select.Option>
              </Select>
              <Select
                placeholder="OS"
                style={{ width: 120, marginLeft: 16 }}
                value={os}
                onChange={(value) => {
                  setOs(value);
                }}
              >
                <Select.Option value="win">Win</Select.Option>
                <Select.Option value="mac">Mac</Select.Option>
                <Select.Option value="linux">Linux</Select.Option>
                <Select.Option value="all">All</Select.Option>
              </Select>
              <Button
                type="primary"
                loading={loading}
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={() => {
                  setLoading(true);

                  if (!fromDate || !toDate) {
                    setLoading(false);
                    return;
                  }

                  let payload = {
                    fromDate: fromDate.format('YYYY-MM-DD'),
                    toDate: toDate.format('YYYY-MM-DD'),
                    appVersion,
                    os
                  };

                  getAdminUsagesDashboard(payload)
                    .then(result => {
                      setUsagesFilter(result.data);
                      setLoading(false);
                    })
                    .catch(err => {
                      message.error("Get Usages Failed");
                      setUsagesFilter([]);
                      setChartData([]);
                      setLoading(false);
                    });
                }}
              >
                Search
              </Button>

              <div style={{ float: "right", display: "flex", alignItems: "center", justifyContent: "center", gap: "24px" }}>
                <Button
                  icon={<DownloadOutlined />}
                  onClick={() => {
                    const templateData = data.map(item => {
                      const userInfo = users.find(user => user.email === item?.email);
                      const startTime = item.details[item.details.length - 1].startTime;
                      const endTime = item.details[0].endTime;

                      return {
                        "Last Usage": item?.lastUsage,
                        "Email": item?.email,
                        "Total Duration": item?.totalDuration,
                        "Duration Value (s)": item?.totalDurationValue / 1000,
                        "Source": userInfo?.source ? capitalizeFirstLetter(userInfo?.source) : "",
                        "Role": userInfo?.role ? capitalizeFirstLetter(userInfo?.role) : "",
                        "GiveAway": userInfo?.isGiveAway ? "X" : "",
                        // "Age": userInfo?.createdAt ? moment(dateNow).diff(userInfo?.createdAt, "seconds", true) : "",
                        "Duration": moment(endTime).diff(startTime, "seconds", true),
                      };
                    });

                    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                    const fileExtension = '.xlsx';
                    const ws = XLSX.utils.json_to_sheet(templateData);
                    const wb = { Sheets: { Data: ws }, SheetNames: ['Data'] };

                    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const dataFile = new Blob([excelBuffer], { type: fileType });
                    const fileName = `usages_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                    FileSaver.saveAs(dataFile, fileName + fileExtension);
                  }}
                >
                  Download
                </Button>
              </div>
            </Col>
          </Row>
        </Card>

        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col span={24}>
              <Card style={{ display: 'block' }}>
                <div style={{ marginBottom: '20px' }}>
                  <Radio.Group value={chartType} onChange={(e) => setChartType(e.target.value)}>
                    <Radio.Button style={{ marginRight: '8px' }} value="Daily">Daily</Radio.Button>
                    <Radio.Button style={{ marginRight: '8px' }} value="Weekly">Weekly</Radio.Button>
                    <Radio.Button style={{ marginRight: '16px' }} value="Monthly">Monthly</Radio.Button>
                  </Radio.Group>
                </div>
                <Line {...config} />
              </Card>

              <Row gutter={24} type="flex" style={{ marginTop: "32px" }}>
                <RenderPieChart loading={loading} pieData={appUsingsData} />
                <RenderPieChart loading={loading} pieData={appOsData} />
                <RenderPieChart loading={loading} pieData={appVersionData} />
              </Row>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={data}
          pagination={{
            defaultPageSize: 20
          }}
          rowKey={(row) => row.email}
          expandable={{
            expandedRowRender: row => expandedRowRender(row)
          }}
        />
      </Layout>
    </Layout>
  )
};

export default Usage;
