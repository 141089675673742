import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { Form, Input, Col, Row, Button, Select, Checkbox, Avatar, message, Modal, Spin, InputNumber } from 'antd';

import ReactQuill from 'react-quill';
import { QuillToolbarContainer, QuillFormats } from 'utils/quill';

import { announcementCreate } from 'apis/announcements';
import { uploadFile } from 'apis/other';

import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import 'react-image-crop/dist/ReactCrop.css';
import './styles.less';

const AnnouncementCreateForm = ({ token, onCancel, onCallback }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [bodyEn, setBodyEn] = useState('');

  const quillRefEn = useRef();
  const imageHandlerEn = () => {
    const quillEditor = quillRefEn.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }
  const modulesEn = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandlerEn
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  const quillRefJp = useRef();
  const imageHandlerJp = () => {
    const quillEditor = quillRefJp.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }
  const modulesJp = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandlerJp
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  return (
    <Form
        form={form}
        layout="vertical"
        className="announcement-modal"
        onFinish={values => {
          setLoading(true);
          let item = {}

          if (values?.titleVn) {
            item.titleVn = values?.titleVn;
          }

          if (values?.titleEn) {
            item.titleEn = values?.titleEn;
          }

          if (values?.titleJp) {
            item.titleJp = values?.titleJp;
          }

          if (values?.actionTitleVn) {
            item.actionTitleVn = values?.actionTitleVn;
          }

          if (values?.actionTitleEn) {
            item.actionTitleEn = values?.actionTitleEn;
          }

          if (values?.actionTitleJp) {
            item.actionTitleJp = values?.actionTitleJp;
          }


          if (values?.actionLink) {
            item.actionLink = values?.actionLink;
          }

          if (bodyEn) {
            item.bodyEn = bodyEn;
          } else {
            item.bodyEn = '';
          }

          if (values?.isPushNotification) {
            item.isPushNotification = values?.isPushNotification;
          } else {
            item.isPushNotification = false;
          }

          if (values?.isSendEmail) {
            item.isSendEmail = values?.isSendEmail;
          } else {
            item.isSendEmail = false;
          }

          if (values?.isActive) {
            item.isActive = values?.isActive;
          } else {
            item.isActive = false;
          }

          announcementCreate(item)
          .then(async(result)=>  {
            debugger
            setLoading(false)
            onCancel()
            onCallback(result.id)       
          })
        }}
        initialValues={{
          isActive: true,
        }}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="titleEn" 
              label="Title" 
              rules={[
                {
                  required: true,
                  message: 'Title!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        {/* <Row gutter={24} type="flex">
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="actionTitleEn" 
              label="Kêu gọi hành động Tiêu đề (En)"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col lg={{ span: 12 }} xs={{ span: 24 }}>
            <Form.Item 
              name="actionLink" 
              label="Kêu gọi hành động (Href)" 
              rules={[
                {
                  message: 'Kêu gọi hành động (Href)!'
                }
              ]}>
              <Input />
            </Form.Item>
          </Col>
        </Row> */}
          
        {/* <Row gutter={24} type="flex">
          <Col lg={{ span: 4 }} xs={{ span: 12 }}>
            <Form.Item name="isPushNotification" label="Push Notification" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
          <Col lg={{ span: 4 }} xs={{ span: 12 }}>
            <Form.Item name="isSendEmail" label="Send Email" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row> */}
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Content">
              <ReactQuill
                ref={quillRefEn}
                modules={modulesEn}
                formats={QuillFormats}
                value={bodyEn}
                onChange={(value) => setBodyEn(value)}
              />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 6 }} xs={{ span: 12 }}>
            <Form.Item name="isActive" label="Active" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            {loading === false ?
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear" onClick={onCancel}>
                  Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
            :
            <Col className="gutter-row" span={24} style={{textAlign: 'right'}}>
              <Button type="clear">
                  Cancel
              </Button>
              <Button type="primary" loading>
                  Save
              </Button>
            </Col>
            }
          </Row>
        </div>
      </Form>
    
  );
};

export default AnnouncementCreateForm;