import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, DatePicker, Row, Col, Input, Card, Space } from 'antd';
import { SearchOutlined, ArrowDownOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import { getReports } from 'apis/reports';
import moment from 'moment';

import './styles.less';

const { RangePicker } = DatePicker;

const Reports = () => {
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);
  const [reports, setReports] = useState([]);
  const [reportsFilter, setReportsFilter] = useState([]);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [users, setUsers] = useState([]);

  const [fromDate, setFromDate] = useState(moment().subtract(7, 'days'));
  const [toDate, setToDate] = useState(moment());

  useEffect(() => {
    (async () => {
      try {
        //fetch data
        setLoading(true)
        let payload = { fromDate: fromDate.format("YYYY-MM-DD"), toDate: toDate.format("YYYY-MM-DD") };
        const [result] = await Promise.all([
          getReports(payload),
        ]);

        setReports(result)
        setReportsFilter(result)
        setLoading(false)
        setUsers([...new Set(result?.map(item => item.user?.email))]);
      } catch (e) {
        setLoading(false)
      }
    })();
  }, [])

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ''
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>
            <div>
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText('');
    confirm();
  };

  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(null);
      setToDate(null);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0])
    }

    if (dates && dates[1]) {
      setToDate(dates[1])
    }
  }

  const columns = [
    {
      title: 'Created At',
      align: 'center',
      dataIndex: 'createdAt',
      width: '4%',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: 'User',
      dataIndex: 'user',
      width: '4%',
      render: (record) => {
        return (
          <div>
            <div>{record?.email}</div>
          </div>
        );
      },
      filters: users?.map(item => {
        return {
          text: item,
          value: item
        }
      }),
      onFilter: (value, record) => record?.user?.email === value,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (record) => {
        return <div>{record}</div>
      },
      width: '10%',
    },
    {
      title: 'Download Log',
      align: 'center',
      width: '2%',
      render: (record) => {
        return <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <a download href={record?.file} target={'_blank'} rel="noopener noreferrer">
            <Button
              type='link'
              ghost
              
              icon={<ArrowDownOutlined />}
            >Download</Button></a>
        </div>
      }
    }
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Reports</h2>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  Today: [moment(), moment()],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                onChange={onChange}
                defaultValue={[fromDate, toDate]}
                allowClear
              />
              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={() => {
                  setLoading(true)
                  let payload = {
                    fromDate: fromDate.format('YYYY-MM-DD'),
                    toDate: toDate.format('YYYY-MM-DD')
                  };

                  getReports(payload)
                    .then(result => {
                      setReports(result)
                      setReportsFilter(result)
                      setLoading(false)
                      setUsers([...new Set(result.map(item => item.user?.email))])
                    })
                    .catch(err => {
                      setLoading(false)
                    })
                }}
              >Search</Button>
            </Col>
          </Row>
        </Card>
        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={reportsFilter}
          pagination={{
            defaultPageSize: 20
          }}
        />
      </Layout>
    </Layout>

  )
};

export default Reports;