import React from 'react';
import { Layout, Menu } from 'antd';
import { Outlet, useNavigate, useLocation } from 'react-router';
import './styles.less';

const { Sider } = Layout;

const SidebarLayout = ({ role }) => {
  let navigate = useNavigate();

  const pathMenu = useLocation().pathname.split('/')
  return (
    <Layout className={`sidebar-page`}>
      <Sider width={200} className="sider-left">
        <Menu
          mode="inline"
          defaultSelectedKeys={[pathMenu[3]]}
          style={{ height: '100%', borderRight: 0, marginTop: 30 }}
        >
          <Menu.Item key="versions" onClick={() => navigate(`/v1/master/versions`)}>Version</Menu.Item>
          <Menu.Item key="announcements" onClick={() => navigate(`/v1/master/announcements`)}>Announcement</Menu.Item>
          <Menu.Item key="tutorials" onClick={() => navigate(`/v1/master/tutorials`)}>Tutorial</Menu.Item>
          <Menu.Item key="superusers" onClick={() => navigate(`/v1/master/superusers`)}>Super User</Menu.Item>
        </Menu>
      </Sider>
      <Layout style={{ padding: '0 24px 24px' }}>
        <Outlet />
      </Layout>
    </Layout>
  );
};

export default SidebarLayout;