/* eslint-disable */
import { useEffect, useState } from "react";
import { auth } from 'utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

import { getProfile } from "apis/profile";
import { useLocation, useNavigate } from "react-router";

export default function useAuth() {
  const [user, setUser] = useState();
  const [loadingAuth, setLoadingAuth] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          console.log('user: ', user)
          //get data
          const [userProfile] = await Promise.all([
            getProfile(user?.uid),
          ]);
  
          setUser(userProfile);
          setLoadingAuth(false);
        } else {
          setLoadingAuth(false);
          setUser(undefined);
  
          if (location.pathname !== "/forgot-password") {
            navigate('/');
          }
        }
      });
    })();
  }, [])

  return { user, loadingAuth };
}
