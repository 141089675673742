import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { Form, Input, Col, Row, Button, Checkbox, Select, Avatar, Modal, message, Spin, InputNumber } from 'antd';

import ReactQuill from 'react-quill';
import { QuillToolbarContainer, QuillFormats } from 'utils/quill';

import { announcementUpdate } from 'apis/announcements';
import { uploadFile } from 'apis/other';

import _ from 'lodash';

import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import 'react-image-crop/dist/ReactCrop.css';
import './styles.less';

const AnnouncementEditForm = ({ token, announcement, onCancel, onCallback }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [bodyVn, setBodyVn] = useState('');
  const [bodyEn, setBodyEn] = useState('');
  const [bodyJp, setBodyJp] = useState('');
  
  const quillRefVn = useRef();
  const imageHandlerVn = () => {
    const quillEditor = quillRefVn.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }
  const modulesVn = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandlerVn
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  const quillRefEn = useRef();
  const imageHandlerEn = () => {
    const quillEditor = quillRefEn.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }
  const modulesEn = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandlerEn
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  const quillRefJp = useRef();
  const imageHandlerJp = () => {
    const quillEditor = quillRefJp.current.getEditor()
    const input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('accept', 'image/*')
    input.click()
    input.onchange = async () => {
      const file = input.files[0]
      
      uploadFile(file)
      .then(url => {
        const range = quillEditor.getSelection();
        quillEditor.insertEmbed(range.index, 'image', url)
      })
      .catch(error => console.log(error));
    }
  }
  const modulesJp = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandlerJp
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  useEffect(() => {
    setBodyVn(_.get(announcement, 'bodyVn', ''));
    setBodyEn(_.get(announcement, 'bodyEn', ''));
    setBodyJp(_.get(announcement, 'bodyJp', ''));
  }, []);
  
  return (
    <Form
        form={form}
        layout="vertical"
        className="announcement-modal"
        onFinish={values => {
          setLoading(true);
          let item = {
            announcementId: announcement?.announcementId
          }

          if (values?.titleEn) {
            item.titleEn = values?.titleEn;
          }

          if (bodyEn) {
            item.bodyEn = bodyEn;
          } else {
            item.bodyEn = '';
          }

          if (values?.isActive) {
            item.isActive = values?.isActive;
          } else {
            item.isActive = false;
          }

          announcementUpdate(item)
          .then(async (result)=> {
            debugger
            setLoading(false)
            onCancel()
            onCallback(result?.id)
          });
        }}
        initialValues={announcement}
      >
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item 
              name="titleEn" 
              label="Title" 
              rules={[
                {
                  required: true,
                  message: 'Please input the Title!'
                }
              ]}
              >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 24 }} xs={{ span: 24 }}>
            <Form.Item label="Content">
              <ReactQuill
                ref={quillRefEn}
                modules={modulesEn}
                formats={QuillFormats}
                value={bodyEn}
                onChange={(value) => setBodyEn(value)}
              />

            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24} type="flex">
          <Col lg={{ span: 6 }} xs={{ span: 12 }}>
            <Form.Item name="isActive" label="Active" valuePropName="checked">
              <Checkbox />
            </Form.Item>
          </Col>
        </Row>
        <div className="ant-modal-footer" style={{marginLeft: -24, marginRight: -24, marginBottom: -24}}> 
          <Row gutter={24} type="flex" style={{textAlign: 'right'}}>
            <Col className="gutter-row" span={12} style={{ textAlign: 'left', paddingLeft: 20 }}>
              {announcement.announcementId}
            </Col>
            {loading === false ?
            <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
              <Button type="primary" htmlType="submit">
                  Save
              </Button>
            </Col>
            :
            <Col className="gutter-row" span={12} style={{textAlign: 'right'}}>
              <Button type="primary" loading>
                  Save
              </Button>
            </Col>
            }
          </Row>
        </div>
      </Form>
  );
};

export default AnnouncementEditForm;