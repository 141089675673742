import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Form, Input, Col, Row, Button, Checkbox, Select, InputNumber, message } from 'antd';
import { storage } from 'utils/firebase';
import { ref, getDownloadURL, uploadBytesResumable, deleteObject } from "firebase/storage";
import { versionUpdate } from 'apis/version';

import ReactQuill from 'react-quill';
import '../../../../../node_modules/react-quill/dist/quill.snow.css';

import { QuillToolbarContainer, QuillFormats } from 'utils/quill';

import _ from 'lodash';

import './styles.less';

const { Option } = Select;

const VersionEditForm = ({ version, onCancel, onCallback }) => {
  const [form] = Form.useForm();

  const [releaseNotesEn, setReleaseNotesEn] = useState('');
  const quillRefEn = useRef(null);

  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);
  const [showPercent, setShowPercent] = useState(false);
  const [fileChanged, setFileChanged] = useState(false);

  function handleChange(event) {
    setFile(event.target.files[0]);
    setFileChanged(true);
  }

  function handleUpload(values) {
    debugger
    console.log('values: ', values)
    if (fileChanged) {
      if (!file) {
        alert("Please choose a file first!")
        return;
      }

      const fileRef = ref(storage, `download/${file.name}`);
      setShowPercent(true);
      const uploadTask = uploadBytesResumable(fileRef, file)

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          // update progress
          setPercent(percent);
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask.snapshot.ref).then(async (url) => {
            let payload = {
              ...values,
              isPreviewUpdate: values?.isPreviewUpdate ? true : false,
              isForceUpdate: values?.isForceUpdate ? true : false,
              urlDownload: url,
              releaseNotesEn,
              versionId: version?.versionId
            }

            let result = await versionUpdate(payload);
            if (result.status === 'success') {
              onCallback(version?.versionId)
            } else {
              message.error(result?.error?.message);
            }

            // If prevFileName == currentFileName, already been replaced
            if (version.urlDownload) {
              debugger
              const prevFileName = decodeURIComponent(version.urlDownload).split("/o/download/")[1].split("?")[0];
              if (prevFileName !== file.name) {
                deleteObject(ref(storage, version.urlDownload)).then(value => {
                  console.log("Delete old file successfully");
                })
              }
            }
          });
        }
      );
    }
    else {
      let payload = {
        ...values,
        isPreviewUpdate: values?.isPreviewUpdate ? true : false,
        isForceUpdate: values?.isForceUpdate ? true : false,
        urlDownload: version.urlDownload,
        releaseNotesEn,
        versionId: version?.versionId,
        isActive: values?.isActive ? values?.isActive : false,
      }
      versionUpdate(payload)
      .then(() => onCallback())
    }
  }

  const modulesBodyEn = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  useEffect(() => {
    setReleaseNotesEn(_.get(version, 'releaseNotesEn', ''));
    setIsForceUpdate(version?.isForceUpdate)
    setIsPreviewUpdate(version?.isPreviewUpdate)
  }, []);

  const [isPreviewUpdate, setIsPreviewUpdate] = useState(false);
  const [isForceUpdate, setIsForceUpdate] = useState(false);
  
  return (
    <Form
      form={form}
      className="version-modal"
      layout="vertical"
      name="pageUpdateForm"
      onFinish={values => {
        handleUpload(values);
      }}
      initialValues={version}
    >
      <Row gutter={24} type="flex">
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
            name="os"
            label="Os"
          >
            <Select
              showSearch
              placeholder="Os"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={'win'}>Win</Option>
              <Option value={'mac'}>Mac</Option>
              <Option value={'linux'}>Linux</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
            name="version"
            label="Version"
            rules={[
              {
                required: true,
                message: 'Please input the Version!'
              }
            ]}
          >
            <Input placeholder='1.0.1' />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
            name="builderNumber"
            label="Builder Number"
            rules={[
              {
                required: true,
                message: 'Build Number!'
              }
            ]}>
            <InputNumber disabled={true} />
          </Form.Item>
        </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
            <Form.Item name="isPreviewUpdate" label="Preview Update" valuePropName="checked">
              <Checkbox 
                disabled={isForceUpdate}
                defaultChecked={isPreviewUpdate} 
                onChange={(e) => setIsPreviewUpdate(e.target.checked)}
              />
            </Form.Item>
          </Col>
        <Col lg={{ span: 6 }} xs={{ span: 24 }}>
          <Form.Item name="isForceUpdate" label="Force Update" valuePropName="checked">
            <Checkbox
              disabled={isPreviewUpdate}
              defaultChecked={isForceUpdate} 
              onChange={(e) => setIsForceUpdate(e.target.checked)}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item
            name="nameEn"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Name!'
              }
            ]}>
            <Input placeholder='Mac 1.28.7 - Native support on M1 chip' />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item
            name="uploadFile"
            label="Upload File"
          >
            {version.urlDownload && <p>Current File: {decodeURIComponent(version.urlDownload).split("/o/download/")[1].split("?")[0]}</p>}
            <Input type="file" onChange={handleChange} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item label="Release Notes">
            <ReactQuill
              ref={quillRefEn}
              modules={modulesBodyEn}
              formats={QuillFormats}
              value={releaseNotesEn}
              placeholder=""
              onChange={(value) => setReleaseNotesEn(value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item name="isActive" label="Active" valuePropName="checked">
            <Checkbox>Active</Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          <Col className="gutter-row" span={12} style={{ textAlign: 'left', paddingLeft: 20 }}>
            {version.versionId}
          </Col>
          <Col className="gutter-row" span={12} style={{ textAlign: 'right' }}>
            {showPercent && <p>Upload Percent: {percent}%</p>}
            <Button type="clear" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" disabled={showPercent}>
              {showPercent ? "Uploading..." : "Save"}
            </Button>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default VersionEditForm;