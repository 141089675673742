/* eslint-disable */
import request from "utils/request";

export const superUserList = async (token) => {
    return request(`admin/superusers`, {}, { method: "GET", token })
};

export const superUserDetail = async (payload, token) => {
    return request(`admin/superuser/${payload}`, {}, { method: "GET", token })
};

export const superUserCreate = async (payload, token) => {
    return request(`admin/superuser`, payload, { method: "POST", token })
};

export const superUserUpdate = async (payload, token) => {
    return request(`admin/superuser`, payload, { method: "PUT", token })
};
