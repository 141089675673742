import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Form, Input, Button, Row, Col, message } from 'antd';
import { LockOutlined, EyeInvisibleOutlined, EyeOutlined, UserOutlined } from '@ant-design/icons';

import { auth } from 'utils/firebase';
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { getProfile } from 'apis/profile';

import logo from 'assets/logo.png';
import './styles.less';

const Login = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    setLoading(true);
    signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        setLoading(false);
        const [admin] = await Promise.all([
          getProfile(userCredential.user?.uid),
        ]);

        if (admin?.isActive) {
          if (admin?.role === "content") {
            navigate(`/v1/posts`);
          } else if (admin?.role === "support") {
            navigate(`/v1/usages`);
          } else {
            navigate(`/v1/users`);
          }
        } else {
          await signOut(auth);
          message.error("Account has been disabled");
        }
      })
      .catch((error) => {
        console.error(error)
        message.error("Wrong Email or Password!");
        setLoading(false);
      });
  };

  return (
    <div className="login-page">
      <Row justify="space-between" style={{ height: window.innerHeight }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} style={{ alignSelf: 'center' }} className="paddingCol">
          <div className="login-logo">
            <img src={logo} alt="logo" style={{ height: 60, borderRadius: 10 }} />
            <h3 className="login-title" style={{ marginTop: 40, marginBottom: 40 }}>
              Admin Login
            </h3>
          </div>
          <div>
            <Form
              name="loginForm"
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              layout="vertical"
            >
              <Form.Item
                label={'Email'}
                name="email"
                rules={[
                  { required: true, message: 'Email' },
                ]}
              >
                <Input
                  size="large"
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={'Email'}
                />
              </Form.Item>
              <Form.Item
                label={'Password'}
                name="password"
                rules={[{ required: true, message: 'Password' }]}
              >
                <Input.Password
                  size="large"
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  placeholder={'Password'}
                  iconRender={(visible) =>
                    visible ? (
                      <EyeOutlined style={{ color: 'black' }} />
                    ) : (
                      <EyeInvisibleOutlined style={{ color: 'black' }} />
                    )
                  }
                />
              </Form.Item>
              <Form.Item>
                {loading === false ? (
                  <Button size="large" type="primary" htmlType="submit" block className="login-form-button">
                    Login
                  </Button>
                ) : (
                  <Button size="large" type="primary" loading={loading} block className="login-form-button">
                    Loading
                  </Button>
                )}
              </Form.Item>
              <Form.Item>
                <p style={{ textAlign: "right" }}>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigate("/forgot-password");
                    }}
                  >
                    Forgot Password
                  </a>
                </p>
              </Form.Item>
            </Form>
          </div>
          <div style={{ paddingTop: '3vh', fontWeight: 'bold' }}>© 2023 NEEP. All rights reserved.</div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
