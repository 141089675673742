import { db } from 'utils/firebase';
import { collection, query, orderBy, getDocs  } from "firebase/firestore";

export const contactList = async () => {
  const q = query(collection(db, "contacts"), orderBy('createdAt', 'desc'));
  const querySnapshot = await getDocs(q);

  let contacts = [];
  querySnapshot.forEach((doc) => {
    contacts.push({
      contactId: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate(),
    });
  });
  return contacts;
};


