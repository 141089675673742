import React, { useState, useRef, useMemo } from 'react';
import { Form, Input, Col, Row, Button, InputNumber, Checkbox, Select } from 'antd';

import { postSectionCreate } from 'apis/posts';
import { uploadFile } from 'apis/other';

import ReactQuill, { Quill } from 'react-quill';
import '../../../../../node_modules/react-quill/dist/quill.snow.css';
import { QuillToolbarContainer, QuillFormats } from 'utils/quill';

import './styles.less';

const AlignStyle = Quill.import('attributors/style/align');
const BlockEmbed = Quill.import('blots/block/embed');

class AudioBlot extends BlockEmbed {
  static create(url) {
    let node = super.create();
    node.setAttribute("src", url);
    node.setAttribute("controls", "");
    return node;
  }

  static value(node) {
    return node.getAttribute("src");
  }
};

AudioBlot.blotName = "audio";
AudioBlot.tagName = "audio";

Quill.register(AudioBlot);
Quill.register(AlignStyle, true);

const PostSectionCreateForm = ({ isSection, post, onCancel, onCallback }) => {
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();
  const [body, setBody] = useState('');
  const quillRef = useRef(null);

  const imageHandler = () => {
    const quillEditor = quillRef.current.getEditor();
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0]

      uploadFile(file)
        .then(url => {
          const range = quillEditor.getSelection();
          quillEditor.insertEmbed(range.index, 'image', url);
          quillEditor.setSelection(range.index + 1, "user");
        })
        .catch(error => console.log(error));
    }
  }

  const videoHandler = () => {
    const quillEditor = quillRef.current.getEditor();
    const input = document.createElement("input");

    input.setAttribute("type", "file");
    input.setAttribute("accept", "audio/*, video/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];

      if (file.type?.includes("video")) {
        uploadFile(file)
          .then(url => {
            const range = quillEditor.getSelection();
            quillEditor.insertEmbed(range.index, "video", url);
            quillEditor.setSelection(range.index + 1, "user");
          })
          .catch(error => console.log(error));
      } else if (file.type?.includes("audio")) {
        input.setAttribute("type", "file");
        uploadFile(file)
          .then(url => {
            const range = quillEditor.getSelection();
            quillEditor.insertEmbed(range.index, "audio", url);
            quillEditor.setSelection(range.index + 1, "user");
          })
          .catch(error => console.log(error));
      }
    }
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: QuillToolbarContainer,
      handlers: {
        'image': imageHandler,
        'video': videoHandler
      }
    },
    clipboard: {
      matchVisual: false
    }
  }), [])

  return (
    <Form
      form={form}
      className="post-modal"
      layout="vertical"
      onFinish={values => {
        setLoading(true)

        let item = {};
        item.postId = post?.postId;

        if (values?.sectionId) {
          item.sectionId = values?.sectionId;
        }

        if (values?.no) {
          item.no = values?.no;
        }

        if (values?.title) {
          item.title = values?.title;
        }

        if (body) {
          item.body = body;
        } else {
          item.body = '';
        }

        if (values?.isActive) {
          item.isActive = values?.isActive;
        } else {
          item.isActive = false;
        }

        postSectionCreate(item)
          .then(() => {
            setLoading(false)
            onCancel()
            onCallback()
          })
      }}
      initialValues={{}}
    >
      {isSection === false && (
        <Row gutter={24} type="flex">
          <Col lg={{ span: 20 }} xs={{ span: 24 }}>
            <Form.Item
              name="sectionId"
              label="Post Section"
              tooltip="where the post belongs to"
              rules={[
                {
                  required: true,
                  message: 'Vui lòng chọn Post Section!'
                }
              ]}
            >
              <Select
                showSearch
                placeholder="Post Section"
              >
                {post?.sections.filter(x => (x?.sectionId === undefined || x?.sectionId === '')).map(item => {
                  return <Select.Option value={item?.postSectionId}>{item.title}</Select.Option>
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={24} type="flex">
        <Col lg={{ span: 20 }} xs={{ span: 24 }}>
          <Form.Item
            name="title"
            label="Title"
            rules={[
              {
                required: true,
                message: 'Title!'
              }
            ]}
          >
            <Input placeholder="Title" />
          </Form.Item>
        </Col>
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item
            name="no"
            label="No."
            rules={[
              {
                required: true,
                message: 'No.!'
              }
            ]}
          >
            <InputNumber min={0} max={1000} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 24 }} xs={{ span: 24 }}>
          <Form.Item label="Content">
            <ReactQuill
              ref={quillRef}
              modules={modules}
              formats={QuillFormats}
              placeholder="Write something amazing..."
              onChange={(value) => setBody(value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24} type="flex">
        <Col lg={{ span: 4 }} xs={{ span: 24 }}>
          <Form.Item name="isActive" label="Active" valuePropName="checked">
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>

      <div className="ant-modal-footer" style={{ marginLeft: -24, marginRight: -24, marginBottom: -24 }}>
        <Row gutter={24} type="flex" style={{ textAlign: 'right' }}>
          {loading === false ?
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear" onClick={onCancel}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Col>
            :
            <Col className="gutter-row" span={24} style={{ textAlign: 'right' }}>
              <Button type="clear">
                Cancel
              </Button>
              <Button type="primary" loading>
                Save
              </Button>
            </Col>
          }
        </Row>
      </div>
    </Form>
  );
};

export default PostSectionCreateForm;