import React, { useState, useEffect, useRef } from 'react';

import { Layout, Button, Table, Modal, Row, Col, Input, Card, Space, Tag } from 'antd';
import { SearchOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';

import SuperUserCreateForm from "./forms/super-user-create.form";

import moment from 'moment';

import './styles.less';
import useToken from 'hooks/useToken';
import { superUserList } from 'apis/superusers';
import SuperUserUpdateForm from './forms/super-user-update.form';

const SuperUsers = () => {
  const { token } = useToken();
  const [loading, setLoading] = useState([]);

  const searchInputEl = useRef(null);

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const [isCreateModal, setIsCreateModal] = useState(false);
  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [superUsers, setSuperUsers] = useState([]);
  const [superUser, setSuperUser] = useState([]);

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setLoading(true);
          const [superUsers] = await Promise.all([
            superUserList(token)
          ]);

          setSuperUsers(superUsers);
          setLoading(false);
        } catch (e) {
          setLoading(false);
        }
      }
    })();
  }, [token]);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={searchInputEl}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters, confirm)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => {
      return record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : ""
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInputEl.current.select(), 100);
      }
    },
    render: text => {
      return (
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <div>
              {(dataIndex === "email" && text?.appsumo !== undefined) && (
                <span style={{ fontWeight: "bold", marginRight: 5, color: "orange" }}>[AppSumo]</span>
              )}
              <Highlighter
                highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
                searchWords={[searchText]}
                autoEscape
                textToHighlight={text[dataIndex] ? text[dataIndex].toString() : ''}
              />
            </div>
          </div>
        </div>
      )
    },
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    setSearchText("");
    confirm();
  };

  const columns = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "createdAt",
      width: "20%",
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      defaultSortOrder: 'descend',
      render: (text, record, index) => {
        return (
          <div>
            <div>{moment(record.createdAt).format('YYYY-MM-DD HH:mm')}</div>
          </div>
        );
      }
    },
    {
      title: "Name",
      width: "15%",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Email",
      width: "25%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Role",
      align: "center",
      dataIndex: "role",
      render: (record) => {
        if (record === 'free') {
          return <Tag>{record.toUpperCase()}</Tag>;
        } else {
          return <Tag color="green">{record.toUpperCase()}</Tag>;
        }
      },
      width: "15%",
      filterMultiple: true,
      filters: [
        {
          text: "Super",
          value: "super",
        },
        {
          text: "Sale",
          value: "sale",
        },
        {
          text: "Support",
          value: "support",
        },
        {
          text: "Fin",
          value: "fin",
        },
        {
          text: "Content",
          value: "content",
        }
      ],
      onFilter: (value, record) => record.role === value,
    },
    {
      title: "Active",
      dataIndex: "isActive",
      render: (record) => {
        if (record) {
          return <Tag color="green">Active</Tag>;
        } else {
          return <Tag color={"red"}>Inactive</Tag>;
        }
      },
      width: "15%",
      align: "center",
      filterMultiple: true,
      filters: [
        {
          text: "Active",
          value: true,
        },
        {
          text: "Inactive",
          value: false,
        },
      ],
      onFilter: (value, record) => record.isActive === value,
    },
    {
      title: "Action",
      align: "center",
      width: "10%",
      render: (record) => {
        return (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Button
              type="link"
              icon={<EditOutlined />}
              onClickCapture={() => {
                setSuperUser(record);
                setIsUpdateModal(true);
              }}
            >
              Edit
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <h2>Super Users</h2>
            </Col>
            <Col
              className="gutter-row"
              span={12}
              style={{ textAlign: "right" }}
            >
              <Button
                type="primary"
                onClick={() => setIsCreateModal(true)}
                icon={<PlusOutlined />}
              >
                Create super user
              </Button>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={superUsers}
          pagination={{
            defaultPageSize: 20
          }}
          rowKey={(row) => row.superUserId}
        />

        <Modal
          visible={isCreateModal}
          title={"Create Super User"}
          onCancel={() => {
            setIsCreateModal(false);
          }}
          destroyOnClose={true}
          width={570}
          centered
          footer={null}
        >
          <SuperUserCreateForm
            onCancel={() => setIsCreateModal(false)}
            onCallback={async () => {
              const [superUsers] = await Promise.all([superUserList(token)]);
              setSuperUsers(superUsers);
              setIsCreateModal(false);
            }}
            token={token}
          />
        </Modal>

        <Modal
          visible={isUpdateModal}
          title={"Update Super User"}
          onCancel={() => {
            setIsUpdateModal(false);
          }}
          destroyOnClose={true}
          width={570}
          centered
          footer={null}
        >
          <SuperUserUpdateForm
            superUser={superUser}
            onCancel={() => setIsUpdateModal(false)}
            onCallback={async () => {
              const [superUsers] = await Promise.all([superUserList(token)]);
              setSuperUsers(superUsers);
              setIsUpdateModal(false);
            }}
            token={token}
          />
        </Modal>
      </Layout>
    </Layout>

  )
};

export default SuperUsers;