import { db } from 'utils/firebase';
import { collection, query, where, doc, getDocs, getDoc, orderBy, deleteDoc  } from "firebase/firestore";

export const getProfile = async (id) => {
  try {
    let admin;

    const docRef = doc(db, "admins", id);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists) {
      return undefined;
    }

    admin = docSnap.data();
    admin.id = docSnap.id;
    return admin;
  }
  catch (err) {
    return undefined;
  }
};
