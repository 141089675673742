import React, { useState, useEffect } from "react";

import { Layout, Button, Table, DatePicker, Row, Col, Card, Tag } from "antd";
import { SearchOutlined, EyeFilled, DownloadOutlined } from "@ant-design/icons";

import _ from "lodash";

import useAuth from "hooks/useAuth";

import moment from "moment";

import "./styles.less";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx-js-style";
import { getSubscriptions } from "apis/subscriptions";

const { RangePicker } = DatePicker;


const Subscriptions = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState([]);

  const [subscriptions, setSubscriptions] = useState([]);
  const [subscriptionsFilter, setSubscriptionsFilter] = useState([]);

  const [statusSubscriptions, setStatusSubscriptions] = useState([]);

  const [fromDate, setFromDate] = useState(moment().subtract(3, "months"));
  const [toDate, setToDate] = useState(moment());

  useEffect(() => {
    (async () => {
      try {
        //setLoading(true)
        if (user) {
          //fetch data
          setLoading(true);
          let payload = {
            fromDate: fromDate.format("YYYY-MM-DD"),
            toDate: toDate.format("YYYY-MM-DD")
          };

          const [subscriptions] = await Promise.all([
            getSubscriptions(payload),
          ]);

          setSubscriptions(subscriptions);
          setSubscriptionsFilter(subscriptions);
          setLoading(false);
        }
      } catch (e) {
        setLoading(false);
      }
    })();
  }, [user]);

  useEffect(() => {
    if (subscriptionsFilter) {
      const statusFilter = _.unionBy(subscriptionsFilter, "status");

      if (statusFilter.length > 0) {
        const status = statusFilter.map(item => {
          return {
            text: _.capitalize(item.status),
            value: item.status,
          };
        });
        setStatusSubscriptions(status);
      }
    }
  }, [subscriptionsFilter]);


  function onChange(dates, dateStrings) {
    if (!dates) {
      setFromDate(null);
      setToDate(null);
    }

    if (dates && dates[0]) {
      setFromDate(dates[0]);
    }

    if (dates && dates[1]) {
      setToDate(dates[1]);
    }
  }

  const renderPlan = (prices) => {
    return prices.map((item, index) => {
      return <div key={index}>{capitalizeFirstLetter(item?.product?.name)}</div>
    })
  };

  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0)?.toUpperCase() + string?.slice(1);
  };

  const columns = [
    {
      title: "Created At",
      align: "center",
      dataIndex: "created",
      width: "8%",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.created).unix() - moment(b.created).unix(),
      render: (text, record, index) => {
        return (
          <div>
            <div>{record?.created ? moment(record.created).format("YYYY-MM-DD HH:mm") : ""}</div>
          </div>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      align: "center",
      render: (record) => {
        if (record === "active") {
          return <Tag color="blue">{record.toUpperCase()}</Tag>;
        } else {
          return <Tag color="default">{record.toUpperCase()}</Tag>;
        }
      },
      width: "4%",
      filterMultiple: true,
      filters: statusSubscriptions,
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Plan",
      dataIndex: "subscription",
      width: "6%",
      render: (value, record) => {
        return (
          <div>{renderPlan(record?.prices)}</div>
        );
      },
    },
    {
      title: "User",
      dataIndex: "customer",
      width: "8%",
      render: (record) => {
        return (
          <div>
            <div>{record?.email}</div>
          </div>
        );
      }
    },
    {
      title: "Cancel At",
      align: "center",
      dataIndex: "cancelAt",
      width: "6%",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.cancel_at).unix() - moment(b.cancel_at).unix(),
      render: (text, record, index) => {
        return (
          <div>
            <div>{record?.cancel_at ? moment(record.cancel_at).format("YYYY-MM-DD HH:mm") : ""}</div>
          </div>
        );
      }
    },
    {
      title: "Canceled At",
      align: "center",
      dataIndex: "canceledAt",
      width: "6%",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.canceled_at).unix() - moment(b.canceled_at).unix(),
      render: (text, record, index) => {
        return (
          <div>
            <div>{record?.canceled_at ? moment(record.canceled_at).format("YYYY-MM-DD HH:mm") : ""}</div>
          </div>
        );
      }
    },
    {
      title: "Current Period Start",
      align: "center",
      dataIndex: "current_period_start",
      width: "6%",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.current_period_start).unix() - moment(b.current_period_start).unix(),
      render: (text, record, index) => {
        return (
          <div>
            <div>{record?.current_period_start ? moment(record.current_period_start).format("YYYY-MM-DD HH:mm") : ""}</div>
          </div>
        );
      }
    },
    {
      title: "Current Period End",
      align: "center",
      dataIndex: "current_period_end",
      width: "6%",
      defaultSortOrder: "descend",
      sorter: (a, b) => moment(a.current_period_end).unix() - moment(b.current_period_end).unix(),
      render: (text, record, index) => {
        return (
          <div>
            <div>{record?.current_period_end ? moment(record.current_period_end).format("YYYY-MM-DD HH:mm") : ""}</div>
          </div>
        );
      }
    },
    {
      title: "",
      align: "center",
      width: "4%",
      render: (record) =>
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Button
            target="_blank"
            href={record?.customer?.stripeLink}
            type="link"
            ghost
            icon={<EyeFilled />}
          >Detail</Button>
        </div>
    }
  ];

  return (
    <Layout className="jobs-page">
      <Layout style={{ paddingLeft: 24, paddingRight: 24, marginTop: 20 }} >
        <Card style={{ marginBottom: 18 }}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
            <Col className="gutter-row" span={12}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h2>Subscriptions</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <RangePicker
                ranges={{
                  "Today": [moment(), moment()],
                  "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                  "This Week": [moment().startOf("week"), moment().endOf("week")],
                  "This Month": [moment().startOf("month"), moment().endOf("month")],
                  "This Quarter": [moment().startOf("quarter"), moment().endOf("quarter")],
                  "This Year": [moment().startOf("year"), moment().endOf("year")],
                }}
                onChange={onChange}
                defaultValue={[fromDate, toDate]}
              />
              <Button
                type="primary"
                style={{ marginLeft: 24 }}
                icon={<SearchOutlined />}
                disabled={!fromDate || !toDate}
                onClick={() => {
                  setLoading(true)
                  let payload = {
                    fromDate: fromDate.unix(),
                    toDate: toDate.unix()
                  };

                  getSubscriptions(payload)
                    .then(result => {
                      setSubscriptions(result);
                      setSubscriptionsFilter(result);
                      setLoading(false);
                    })
                    .catch(err => {
                      setLoading(false);
                    });
                }}
              >Search</Button>
              <Button
                style={{ float: "right" }}
                type={"link"}
                icon={<DownloadOutlined />}
                onClick={() => {
                  const templateData = subscriptionsFilter.map(item => {
                    return {
                      "Created At": moment(item?.created).format("YYYY-MM-DD HH:mm"),
                      "User": item?.customer?.email,
                      "Plan": renderPlan(item?.prices),
                      "Status": item?.status?.toUpperCase(),
                    };
                  });

                  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                  const fileExtension = ".xlsx";
                  const ws = XLSX.utils.json_to_sheet(templateData);
                  const wb = { Sheets: { Data: ws }, SheetNames: ["Data"] };

                  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                  const data = new Blob([excelBuffer], { type: fileType });
                  const fileName = `subscriptions_${moment().format("MMMM_Do_YYYY_h_mm").trim()}`;
                  FileSaver.saveAs(data, fileName + fileExtension);
                }}
              >Download</Button>
            </Col>
          </Row>
        </Card>

        <Table
          loading={loading}
          bordered
          columns={columns}
          dataSource={subscriptionsFilter}
          pagination={{
            defaultPageSize: 20
          }}
        />
      </Layout>
    </Layout>

  )
};

export default Subscriptions;