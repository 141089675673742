import { db } from 'utils/firebase';
import { Timestamp, collection, query, orderBy, where, getDocs } from "firebase/firestore";

export const getReports = async (payload) => {
  debugger
  try {
    let q = query(collection(db, "reports"), orderBy('createdAt', 'desc'));
    console.log('payload: ', payload)
    
    //fromDate, toDate
    if (payload.fromDate) {
      let start =  Timestamp.fromDate(new Date(payload.fromDate))
      q = query(q, where('createdAt', '>', start));
    }
    if (payload.toDate) {
      let end = Timestamp.fromDate(new Date(new Date(`${payload.toDate} 23:59:59`)))
      q = query(q, where('createdAt', '<', end));
    }

    const querySnapshot = await getDocs(q);

    let items = [];
    querySnapshot.forEach((doc) => {
      items.push({
        reportId: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
        user: {
          ...doc.data().user,
          createdAt: doc.data().user?.createdAt?.toDate(),
        }
      });
    });
    debugger
    return items;
  } catch (error) {
    debugger
    console.log(error)
    return []
  }
};