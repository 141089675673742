/* eslint-disable */
import { useEffect, useState } from "react";
import { auth } from 'utils/firebase';
import { onAuthStateChanged } from 'firebase/auth';

export default function useToken() {
  const [user, setUser] = useState();
  const [token, setToken] = useState();
  const [loadingToken, setLoadingToken] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, user => { 
      if (user) {
        user
        .getIdToken(true)
        .then(async(token) => {
          setUser(user);
          setToken(token);
          setLoadingToken(false);
        });
      } else {
        setLoadingToken(false);
      }
    });
  }, [])

  return {token, loadingToken, user};
}
