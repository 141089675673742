/* eslint-disable */
import React, { useState, useEffect } from 'react';

import _ from 'lodash';
import './styles.less';

import { Modal, Button, Tag, Table, Card, Row, Col, message } from 'antd';
import { EditOutlined, PlusCircleOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusSquareOutlined, MinusSquareOutlined } from '@ant-design/icons';

import { postList, postDetail, postSectionDelete } from 'apis/posts';
import PostSectionCreateForm from './forms/post-section-create.form';
import PostSectionUpdateForm from './forms/post-section-update.form';

import useToken from 'hooks/useToken';

const PostPage = () => {
  const { token } = useToken();
  const [isCreateSectionModal, setIsCreateSectionModal] = useState(false)
  const [isUpdateSectionModal, setIsUpdateSectionModal] = useState(false)
  const [isSection, setIsSection] = useState();

  const [expandedPostKey, setExpandedPostKey] = useState([]);
  const [expandedSectionKey, setExpandedSectionKey] = useState([]);

  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState();
  const [postSection, setPostSection] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (token) {
        try {
          setLoading(true)
          const [posts] = await Promise.all([
            postList(token),
          ]);

          setPosts(posts.filter(item => item.postId === process.env.REACT_APP_TUTORIAL_ID))
          setExpandedPostKey(posts.map(x => x.postId))

          setLoading(false)
        } catch (e) {
          setLoading(false)
        }
      }
    })();
  }, [token]);

  const columns = [
    {
      title: 'Title',
      width: '50%',
      render: (record) => {
        return (
          <div>
            {_.get(record, 'isLongform', '') === true && (
              <div className='longform'>
                <Row gutter={24} type="flex" style={{ marginTop: 24, marginBottom: 15 }}>
                  <Col span={12}>
                    <div style={{ fontWeight: 'bold', marginTop: 5, marginLeft: 2 }}>Content<Button
                      style={{ marginLeft: 8 }}
                      type="link"
                      icon={!expandedPostKey.includes(record.postId) ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
                      ghost
                      onClick={() => {
                        if (expandedPostKey.includes(record.postId)) {
                          //remove
                          setExpandedPostKey(expandedPostKey.filter(x => x !== record.postId))
                        } else {
                          //add
                          setExpandedPostKey([...expandedPostKey, record.postId])
                        }
                      }}
                    /></div>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button
                      onClick={() => {
                        setPost()
                        let postId = _.get(record, 'postId', '');
                        setIsSection(true);
                        postDetail(postId, token)
                          .then(result => {
                            debugger
                            setPost(result)
                            setIsCreateSectionModal(true)
                            setLoading(false)
                          })
                          .catch(err => {
                            debugger
                            console.log(err)
                            setLoading(false)
                          })
                      }}
                      type={'primary'}
                      ghost
                      icon={<PlusCircleOutlined />}
                    >Add New Section</Button>
                  </Col>
                </Row>
                {expandedPostKey.includes(record.postId) && (
                  _.get(record, 'sections') !== null && _.get(record, 'sections', []).sort((a, b) => {
                    return (a?.no - b?.no)
                  }).filter(x => (x?.sectionId === undefined || x?.sectionId === '')).map(item => {
                    return (
                      <>
                        {/* renderSection */}
                        <Card style={{ marginTop: 10, marginBottom: 10, backgroundColor: '#EDEFF0' }}>
                          <Row gutter={24} type="flex">
                            <Col span={12}>
                              <div style={{ fontWeight: '600', marginTop: 5 }}>{item?.no.toString().toUpperCase()}. {item?.title.toUpperCase()} {item?.isActive === true ? <Tag style={{ marginLeft: 5 }}>Active</Tag> : <Tag color={'red'} style={{ marginLeft: 5 }}>In-active</Tag>}
                                {_.get(record, 'sections', []).filter(x => x?.sectionId === item?.postSectionId).length !== 0 && (
                                  <Button
                                    style={{ marginLeft: 8 }}
                                    type="link"
                                    icon={!expandedSectionKey.includes(item.postSectionId) ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
                                    ghost
                                    onClick={() => {
                                      if (expandedSectionKey.includes(item.postSectionId)) {
                                        //remove
                                        setExpandedSectionKey(expandedSectionKey.filter(x => x !== item.postSectionId))
                                      } else {
                                        //add
                                        setExpandedSectionKey([...expandedSectionKey, item.postSectionId])
                                      }
                                    }}
                                  />
                                )}

                              </div>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                              <Button
                                style={{ marginLeft: 8 }}
                                type="link"
                                icon={<EditOutlined />}
                                ghost
                                onClick={() => {
                                  setPost()
                                  let postId = _.get(record, 'postId', '');

                                  if (item?.sectionId) {
                                    setIsSection(false)
                                  }

                                  postDetail(postId, token)
                                    .then(result => {
                                      setPost(result)
                                      setPostSection(item)
                                      setIsUpdateSectionModal(true)
                                      setLoading(false)
                                    })
                                    .catch(err => setLoading(false))
                                }}
                              />
                            </Col>
                          </Row>
                        </Card>

                        {/* renderPost */}
                        {expandedSectionKey.includes(item.postSectionId) && (
                          _.get(record, 'sections', []).sort((a, b) => {
                            return (a?.no - b?.no)
                          }).filter(x => x?.sectionId === item?.postSectionId).map(item => {
                            return (
                              <Card style={{ marginTop: 10, marginBottom: 10, paddingLeft: 40 }}>
                                <Row gutter={24} type="flex">
                                  <Col span={12}>
                                    <div style={{ fontWeight: '600', marginTop: 5 }}>{item?.no}. {item?.title} {item?.isActive === true ? <Tag style={{ marginLeft: 5 }}>Active</Tag> : <Tag color={'red'} style={{ marginLeft: 5 }}>In-active</Tag>}</div>
                                  </Col>
                                  <Col span={12} style={{ textAlign: 'right' }}>
                                    <Button
                                      style={{ marginLeft: 8 }}
                                      type="link"
                                      icon={<EditOutlined />}
                                      ghost
                                      onClick={() => {
                                        setPost()
                                        let postId = _.get(record, 'postId', '');

                                        if (item?.sectionId) {
                                          setIsSection(false)
                                        }

                                        postDetail(postId, token)
                                          .then(result => {
                                            setPost(result)
                                            setPostSection(item)
                                            setIsUpdateSectionModal(true)
                                            setLoading(false)
                                          })
                                          .catch(err => setLoading(false))
                                      }}
                                    />
                                    <Button
                                      style={{ marginLeft: 8 }}
                                      type="link"
                                      icon={<DeleteOutlined />}
                                      ghost
                                      danger
                                      onClick={() => {
                                        Modal.confirm({
                                          title: 'Confirm',
                                          icon: <ExclamationCircleOutlined />,
                                          content: 'Bạn có chắc chắn muốn xoá?',
                                          okText: 'Ok',
                                          cancelText: 'No',
                                          onOk: () => {
                                            let postId = _.get(record, 'postId', '');
                                            postSectionDelete({ postId, postSectionId: item.postSectionId }, token)
                                              .then(async (result) => {
                                                setLoading(true)
                                                message.success('Section đã được xoá thành công')
                                                const [posts] = await Promise.all([
                                                  postList(token),
                                                ]);
                                                setPosts(posts);
                                                setLoading(false)
                                              })
                                              .catch(err => console.log(err))
                                          }
                                        });
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Card>
                            )
                          })
                        )}
                      </>
                    )
                  })
                )}
              </div>
            )}
          </div>
        )
      }
    },
  ];

  return (
    <>
      <Card style={{ marginBottom: 18 }}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} justify="space-between">
          <Col className="gutter-row" span={12}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <h2>Tutorial</h2>
            </div>
          </Col>
        </Row>
      </Card>

      <Table
        showHeader={false}
        loading={loading}
        bordered
        columns={columns}
        dataSource={posts}
        pagination={false}
      />

      <Modal
        visible={isCreateSectionModal}
        title={`Section / Create`}
        onCancel={() => {
          Modal.confirm({
            title: 'Do you sure you want to exit?',
            icon: null,
            onOk() {
              setIsCreateSectionModal(false)
            },
            okText: 'Yes',
            onCancel() { },
            cancelText: 'No',
          });
        }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <PostSectionCreateForm
          isSection={isSection}
          token={token}
          post={post}
          onCancel={() => setIsCreateSectionModal(false)}
          onCallback={async () => {
            const [posts] = await Promise.all([
              postList(token),
            ]);
            setPosts(posts.filter(item => item.postId === process.env.REACT_APP_TUTORIAL_ID))
          }}
        />
      </Modal>
      <Modal
        visible={isUpdateSectionModal}
        title={`Section / Update`}
        onCancel={() => {
          Modal.confirm({
            title: 'Do you sure you want to exit?',
            icon: null,
            onOk() {
              setIsUpdateSectionModal(false)
            },
            okText: 'Yes',
            onCancel() { },
            cancelText: 'No',
          });
        }}
        destroyOnClose={true}
        width={980}
        centered
        footer={null}
      >
        <PostSectionUpdateForm
          isSection={isSection}
          token={token}
          post={post}
          postSection={postSection}
          onCancel={() => setIsUpdateSectionModal(false)}
          onCallback={async () => {
            const [posts] = await Promise.all([
              postList(token),
            ]);
            setPosts(posts.filter(item => item.postId === process.env.REACT_APP_TUTORIAL_ID))
          }}
        />
      </Modal>
    </>
  )
};

export default PostPage;