/* eslint-disable */
import request from "utils/request";
import { db } from 'utils/firebase';
import { collection, query, doc, orderBy, getDocs, getDoc, addDoc, updateDoc, serverTimestamp  } from "firebase/firestore";

export const announcementList = async () => {
  const q = query(collection(db, "announcements"), orderBy('updatedAt', 'desc'));
  const querySnapshot = await getDocs(q);

  let announcements = [];
  querySnapshot.forEach((doc) => {
    announcements.push({
      announcementId: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toDate(),
      updatedAt: doc.data().updatedAt.toDate(),
    });
  });
  return announcements;
};

export const announcementDetail = async (payload) => {
  const docRef = doc(db, "announcements", payload?.announcementId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return {
      ...docSnap.data(),
      createdAt: docSnap.data().createdAt.toDate(),
      announcementId: docSnap.id,
    }
  } else {
    return undefined
  }
};

export const announcementCreate = async (payload) => {
  try {
    const docRef = await addDoc(collection(db, "announcements"), {
      ...payload,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: docRef.id
    };
  } catch (error) {
    debugger
    return {
      status: 'error',
      error,
    };
  }
};

export const announcementUpdate = async (payload) => {
  try {
    const announcementId = payload?.announcementId;
    delete payload.announcementId;
    
    await updateDoc(doc(db, "announcements", announcementId), {
      ...payload,
      updatedAt: serverTimestamp(),
    });
    return {
      status: 'success',
      id: announcementId
    };
  } catch (error) {
    return {
      status: 'error',
      error,
    };
  }
};

export const announcementPhotoUpload = async (payload, token) => {
  const formData = new FormData();
  formData.append('file', payload?.photo);
  return request(`admin/announcement/photo/${payload?.announcementId}`, formData, { method: "POST", token })
};