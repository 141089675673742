/* eslint-disable */
import { db } from 'utils/firebase';
import { Timestamp, collection, where, query, doc, orderBy, getDocs, getDoc, addDoc, updateDoc, deleteDoc, serverTimestamp } from "firebase/firestore";

export const getBillings = async (payload) => {
  try {
    let q = query(collection(db, "customers"));
    const querySnapshot = await getDocs(q);

    let payments = [];

    //customers
    await Promise.all(querySnapshot.docs.map(async (docCus) => {
      let qPayment = query(collection(db, "customers", docCus.id, "payments"),
        orderBy('created', 'desc')
      );

      //fromDate, toDate
      if (payload.fromDate) {
        qPayment = query(qPayment, where('created', '>', payload.fromDate));
      }
      if (payload.toDate) {
        qPayment = query(qPayment, where('created', '<', payload.toDate));
      }

      const queryPaymentsSnapshot = await getDocs(qPayment);
      await Promise.all(queryPaymentsSnapshot.docs.map(async (payment) => {
        let prices = [];

        if (payment.data()?.prices) {
          await Promise.all(payment.data()?.prices?.map(async (docPrice) => {
            let price;
            const docPriceSnap = await getDoc(docPrice);

            if (docPriceSnap.exists()) {
              price = docPriceSnap.data();
              let product;
              const docProductRef = doc(db, "products", price?.product);
              const docProductSnap = await getDoc(docProductRef);

              if (docProductSnap.exists()) {
                product = docProductSnap.data();
              }

              prices.push({
                ...price,
                product
              });
            }
          }));

          payments.push({
            paymentId: payment.id,
            ...payment.data(),
            prices,
            customer: docCus.data(),
          });
        }
      }))

    }));

    return payments;
  } catch (error) {
    console.log(error)
    return []
  }
};
